<script setup lang="ts">
const signWithX = async () => {
  try {
    trackEvent("login-attempt");
    // wait 2.5s before redirecting to the twitter login page
    await new Promise((resolve) => setTimeout(resolve, 3000));
    const twitterResponse = await apiService.getTwitterLoginUrl();
    const { redirectUrl } = twitterResponse.data;
    window.location.href = redirectUrl;
  } catch (error) {
    console.error(error);
  }
};
</script>

<template>
  <button class="text-sm" @click="signWithX">
    <slot>
      Sign up via
      <IconsTweetIcon />
    </slot>
  </button>
</template>
